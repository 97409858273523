body {
	background: #2A323F url('../img/welcome.jpg') no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

#welcome {
	position: fixed;
	top: 50%;
	left: 50%;
	margin: -162.5px 0 0 -190px;
	width: 380px;
	-webkit-perspective: 800;
	-ms-perspective: 800;
	-moz-perspective: 800;
	perspective: 800;
}

	#welcome .logo {
		display: block;
		height: 76px;
		padding: 18px;
		text-align: center;
	}
	
	#welcome .logo:hover {
		background-color: rgba(255,255,255,.1);
	}

	#welcome form {
	
	}
	
		#welcome form .wsForm_text {
			margin-top: 10px;
			margin-right: 39px;
		}

			#welcome form .wsForm_text input {
				border: none;
				padding: 10px 10px 10px 29px; 
				background: rgba(255,255,255,.9);
			}

			#welcome form .wsForm_text input:focus {
				background: #FFF;
			}
			


			#welcome form .wsForm_text:before {
				position: absolute;
				top: 22px;
				left: 7px;
				height: 18px;
				width: 18px;
				margin-top: -9px;
				background: transparent url('../img/workspace_auth.png') 0 0 no-repeat;
				content: '';
			}
			
			#welcome form .fail .wsForm_fieldError {
				display: block;
			}
			
			#welcome form .wsForm_text.login:before {
				background-position: 0 0;	
			}
			
			#welcome form .wsForm_text.pass:before {
				background-position: 0 -18px;	
			}

			#welcome form .wsForm_button {
				background: #00a1f5;
				color: #FFF;
				padding: 10px 35px;
				margin-top: 10px;
				border-radius: 3px;
			}
			

		#welcome .link {
			margin: 20px 0 0;
			display: block;
			color: rgba(255,255,255,.8);
			font-size: 1em;
			text-align: center;
			padding: 9px 0 9px 23px;
			position: relative;
		}
		
			#welcome .link span {
				border-bottom: 1px rgba(255,255,255,.8) dashed;
			}
			
			#welcome .link:hover span,
			#welcome .link:focus span {
				border-bottom: 1px rgba(255,255,255,.8) solid;
			}
			
			#welcome .link:before {
				position: absolute;
				top: 50%;
				left: 0;
				width: 16px;
				height: 16px;
				margin: -7px 0 0;
				background: transparent url('../img/workspace_auth.png') no-repeat 0 0;
				opacity: .8;
				content: '';
			}
			
			#welcome .link.forgot:before {
				background-position: -1px -37px;
			}
			
			#welcome .link.enter:before {
				background-position: -1px -55px;
			}

		#welcome form .button {
			margin: 15px 0 0 20px;
			padding: 5px 20px 5px;
		}

	#welcome .card {
		width: 100%;
		height: 100%;
		-webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
		-ms-transform-style: preserve-3d;
		transform-style: preserve-3d;
		-webkit-transition: .5s;
		-ms-transition: .5s;
		-moz-transition: .5s;
		transition: .5s;
	}

	#welcome .card.forgot {
		-webkit-transform: rotateY(-180deg);
		-moz-transform: rotateY(-180deg);
		-ms-transform: rotateY(-180deg);
		transform: rotateY(-180deg);
	}

		#auth,
		#forgot {
			position: relative;
			width: 100%;
			height: 100%;
			background: #2A323F;
			-webkit-backface-visibility: hidden;
			-moz-backface-visibility: hidden;
			-ms-backface-visibility: hidden;
			backface-visibility: hidden;
			z-index: 2;
		}

			#auth .wsForm_block,
			#forgot .wsForm_block {
				padding: 0 20px 20px;
			}

				#auth .wsForm_blockName span,
				#forgot .wsForm_blockName span {
					background: #2A323F;
					color: rgba(255,255,255,.7);
				}
				
				#auth .wsForm_blockName:before,
				#forgot .wsForm_blockName:before {
					background: rgba(255,255,255,.4);
				}

		#auth {
			z-index: 1;
		}
				
		#forgot {
			-webkit-transform: rotateY(180deg);
				-ms-transform: rotateY(180deg);
					transform: rotateY(180deg);
		}

			#forgot .text {
				color: rgba(255,255,255, .8);
				line-height: 130%;
				margin-top: 10px;
			}

/* sdasdasd */

#login_wrap {
	position: fixed;
	top: 50%;
	left: 50%;
	margin: -166px 0 0 -208px;
}
	
	#login {
		background: #FFF;
		box-shadow: 0 0 3px rgba(0, 0, 0, .1);
	}
	
	#login .title {
		padding: 30px 30px 10px;
		text-align: center;
		font-size: 1.5em;
		color: #444;
	}

	#login form {
		width: 416px;
		background: #FFF;
	}

		#login #login_auth {
			padding: 20px 30px;
		}
		
		#login #login_activate {
			padding: 0 30px 20px 30px;
		}

		#login form .important {
			margin: 20px 0;
			line-height: 17px;
			padding-left: 32px;
			background: transparent url('../img/important.png') 5px 50% no-repeat;
		}

		#login form .input {
			margin: 0 0 20px 0;
		}	

		#login form .login.input,
		#login form .pass.input {
			margin-right: 42px;
		}
		
				#login form input {
					font: 17px/19px Arial, Tahoma, sans-serif;
				}
				
				#login form .login input,
				#login form .pass input {
					padding-left: 32px;
				}
				
				#login form .login:after,
				#login form .pass:after {
					position: absolute;
					top: 50%;
					left: 10px;
					width: 17px;
					height: 18px;
					margin-top: -9px;
					background: transparent url('../img/login_icon.png') no-repeat;
					content: '';
				}
				
				#login form .login:after {
					background-position: -2px -1px;
				}
				
				#login form .login.filled:after,
				#login form .login.focus:after {
					background-position: -2px -21px;
				}
				
				#login form .pass:after {
					background-position: -2px -41px;
				}
				
				#login form .pass.filled:after,
				#login form .pass.focus:after {
					background-position: -2px -61px;
				}
				
					#login form .login .error,
					#login form .pass .error {
						right: -34px;
					}
	
		#login form button {
			padding: 6px 30px;
			font-size: 16px;
		}
	
		#login form .input_border {
			margin: 0 0 20px 0;
		}
		
		#login form button {
			margin-bottom: 10px;	
		}
		
	#login_footer {
		margin: 20px auto 30px;
	}
	
		#login_footer .logo {
			float: right;
			background: transparent url('../img/logo.png') no-repeat 0 0;
			height: 50px;
			width: 116px;
		}
		
		#login_footer .logo:hover {
			background-position: 0 -50px;
		}
		
		#login_footer .support {
			float: left;
			color: #888;
			font-size: 14px;
			line-height: 17px;
			-webkit-user-select: auto;
			-moz-user-select: auto;
			-ms-user-select: auto;
			-o-user-select: auto;
			user-select: auto;
		}

			#login_footer .support span {
				display: block;
				font-weight: bold;
				line-height: 16px;
			}
			
			#login_footer .support a {
				color: #888;
			}

			#login_footer .support a:hover {
				color: #0076b2;
			}
	